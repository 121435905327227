import React, { Suspense, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { actions } from 'slices/app.slice'
import Fallback from 'components/Fallback'
import styles from 'index.module.scss'
import { Image } from 'react-bootstrap'
import { images } from './theme'
import axios from 'axios'
import { database } from './utils/firebase'

const Auth = React.lazy(() => import('./pages/auth'))
const Dashboard = React.lazy(() => import('./pages/dashboard'))

function Router(props) {
  const dispatch = useDispatch()
  const { checked, loggedIn } = useSelector((state) => state.app)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search || props.urlParams)

    let utmParams = {};
    urlParams.forEach((value, key) => {
      if (key !== 'source' && key !== 'environment' && key !== 'user_id') {
        utmParams[key] = value;
      }
    });

    const key = utmParams.gclid || utmParams.gbraid || utmParams.wbraid || utmParams.gclsrc || null;
    if (Object.keys(utmParams).length <= 0) {
      utmParams = null;
    }
    const source = urlParams.get('source') || null
      const environment = urlParams.get('environment') || null
      const user_id = urlParams.get('user_id') || null
    dispatch(actions.authenticate(source, utmParams, {environment, user_id}))
    try{
      if (key){
        let updates = {};
        if (source){
          updates[`googleAds/${key}/source`] = source;
        }
        const utmKeys = Object.keys(utmParams);
        utmKeys.forEach((utmKey) => {
          updates[`googleAds/${key}/${utmKey}`] = utmParams[utmKey];
        });
        updates[`googleAds/${key}/updated_at`] = Date.now();
        if (Object.keys(updates).length > 0){
            database.ref().update(updates).then(data => {console.log('googleAds updated!');
            }).catch((error) => {
                console.log('googleAds error', error?.data || error);
            });
        }
      }
    }
    catch (e){
      console.log("error in uploadUsersUpdate", e)
    }
  }, [])

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    const crd = pos.coords;
    dispatch(actions.setLocationInfo(crd ? { latitude:crd.latitude, longitude: crd.longitude, accuracy: crd.accuracy } : null))

    console.log("Your current position is:", crd);
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {

    if (navigator && navigator.geolocation) {
      if (navigator.permissions){
        navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
              console.log(result);
              if (result.state === "granted") {
                //If granted then you can directly call your function here
                navigator.geolocation.getCurrentPosition(success, errors, options);
              } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                navigator.geolocation.getCurrentPosition(success, errors, options);
              } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
              }
            });
      }

    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, [])

  if (!checked) {
    return (
      <div className={styles.background}>
        <div className={styles.logo}>

          <Image
            src={images.ydoLogoWhite}
            alt="notification"
            priority
          />
          <span className={styles.text}>Your Doctors Online</span>
        </div>
      </div>
    )
  }

  return (
    <div>
      <BrowserRouter>
        <Suspense fallback={<Fallback />}>
          {!loggedIn ? (
            <Routes>
              <Route path="*" element={<Auth />} />
              <Route path="/auth/*" element={<Auth />} />
            </Routes>
          ) : (
            <Routes>
              <Route path={"*"} element={<Dashboard />} />
            </Routes>
          )}
        </Suspense>
      </BrowserRouter>
    </div>
  )
}

export default Router
