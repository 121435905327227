import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { loadFontIcons } from 'components/FontIcon'
import store from 'utils/store'
import Router from './routes'
import styles from "./index.module.scss"

function App() {
  useEffect(() => {
    loadFontIcons()
  }, [])
  const urlParams = window.location.search;

  return (
    <Provider store={store}>
          <Router urlParams={urlParams}/>
    </Provider>
  )
}

export default App
