import axios from 'axios'

export const getIP = (callback) => {
    fetch('https://api.ipify.org?format=json', { mode: 'cors' })
        .then((resp) => resp.json())
        .then((myip) => {
            console.log('myip ', myip)
            callback(myip.ip)
        })
        .catch(err => {
            console.log('fetch ip error', err)
            fetch('https://ipv4.jsonip.com', { mode: 'cors' })
                .then((resp) => resp.json())
                .then((myip) => {
                    console.log('myip ', myip)
                    callback(myip.ip)
                })
                .catch(err => {
                    console.log('fetch ip error', err)
                    return callback(null)
                })
        })
}

export const getLatLngWithIP = (ip, callback) => {
    if (ip) {
        fetch(`http://api.ipstack.com/${ip}?access_key=5ef1f8556f9c4c585f7892e111676ff3`, { mode: 'cors' })
            .then((resp) => resp.json())
            .then((locationData) => {
                console.log('locationData', locationData)
                callback(locationData)

            })
            .catch(err => {
                console.log('fetch ip error', err)
                callback(null)
            })
    } else {
        callback(null)
    }

}

export const getAddressWithLatLng = (userLocationData, callback) => {
    if (userLocationData) {
        axios({
            method: 'GET',
            headers: {
                'User-Agent': 'ydo',
                'Content-Type': 'text/plain',
            },
            url: `https://nominatim.openstreetmap.org/reverse?lat=${userLocationData.latitude}&lon=${userLocationData.longitude}&zoom=18&addressdetails=1&format=jsonv2&extratags=0&accept-language=en`,
        })
            .then(response => {
                console.log('nominatim response', response)
                callback({
                    ...response?.data?.address,
                    complete_address: response?.data?.display_name,
                })

            })
            .catch(error => {
                callback(null)
                console.log('error', error)
            })
    } else {
        callback(null)
    }

}
