import ydoLogo from 'assets/images/logo_white.png'
import hamburger from 'assets/images/hamburger.png';
import male from 'assets/images/male_4.png'
import backButtonIcon from 'assets/images/icon_back@2x.png'
import attachment from 'assets/images/attahcment.png'
import send from 'assets/images/sendIcon.svg'
import googleIcon from 'assets/images/icon_google.png'
import appleIcon from 'assets/images/icon_apple.png'
import downloadOnAppStore from 'assets/images/downloadOnAppStore.png'
import downloadOnPlayStore from 'assets/images/downloadOnPlayStore.png'
import downloadAppChatView from 'assets/images/downloadAppChatView.png'
import downloadAppPrescriptionView from 'assets/images/downloadAppPrescriptionView.png'
import ydoLogoWhite from 'assets/images/logo_white@2x.png'
import emailLogo from 'assets/images/illustration_email_sent.png'
import cancelIcon from 'assets/images/cancel.png'
import attachDocument from 'assets/images/document.png'
import attachGallery from 'assets/images/gallery.png'
import attachCamera from 'assets/images/photo-camera.png'
import attachVideo from 'assets/images/video-camera.png'
import nurseIcon from 'assets/images/free_trial.svg'
import registerQRCode from 'assets/images/register-qr-code.png'

export const images = {
  ydoLogo,
  ydoLogoWhite,
  male,
  backButtonIcon,
  attachment,
  send,
  appleIcon,
  googleIcon,
  downloadOnAppStore,
  downloadOnPlayStore,
  downloadAppPrescriptionView,
  downloadAppChatView,
  emailLogo,
  hamburger,
  cancelIcon,
  attachDocument,
  attachGallery,
  attachCamera,
  attachVideo,
  nurseIcon,
  registerQRCode
}

export default {}
